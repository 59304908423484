// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.
// $message.success(`${$t('label.copied.clipboard')} : ${name}`)
<template>
  <a-spin :spinning="loading">
    <a-card class="spin-content no-padding no-outline sm-no-padding" :bordered="bordered" :title="title" id="infoCard">
      <!-- WP Implementation -->
      <div>
        <!-- Add License Tags Modal -->
        <a-modal
          :title="$t('label.add.license')"
          @ok="handleLicenseSubmit('add')"
          @cancel="closeAction"
          okText="OK"
          cancelText="Cancel"
          v-model:visible="showAddLicenseModal"
          :closable="true"
          centered>
            <a-spin :spinning="modalLoading">
                <!-- Select for Template Tags -->
                <a-select
                  id="addLicenseSelect"
                  style="width: 55%;"
                  :placeholder="$t('label.licence')"
                  @change="handleChange"
                  v-if="['Admin'].includes($store.getters.userInfo.roletype) && resource.templatetype">
                  <a-select-option
                    v-for="tag in selectedTagInfoWithoutNoBill"
                    :key="tag.key"
                    :disabled="templateTags.length > 0 && (templateTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && templateTags.filter(x => x.tagType === 'OS').length >=1))">
                    <a-tag style="cursor: not-allowed" v-if="templateTags.length > 0 && (templateTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && templateTags.filter(x => x.tagType === 'OS').length >=1))" :color="templateTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && templateTags.filter(x => x.tagType === 'OS').length >=1)? 'grey' : tag.colors">{{ tag.title }}</a-tag>
                    <a-tag v-else style="cursor: pointer" :color="tag.colors">{{ tag.title }}</a-tag>
                  </a-select-option>
                </a-select>
                <!-- Select for All Tags as Admin-->
                <a-select
                  id="addLicenseSelect"
                  style="width: 55%;"
                  :placeholder="$t('label.licence')"
                  @change="handleChange"
                  v-else-if="['Admin'].includes($store.getters.userInfo.roletype)">
                  <a-select-option
                    v-for="tag in existingTags"
                    :key="tag.key"
                    :disabled="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)">
                    <a-tag style="cursor: not-allowed" v-if="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)" :color="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)? 'grey' : tag.colors">{{ tag.title }}</a-tag>
                    <a-tag v-else style="cursor: pointer" :color="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 ? 'grey' : tag.colors">{{ tag.title }}</a-tag>
                  </a-select-option>
                </a-select>
                <!-- Select for All Tags as Normal Account or Reseller-->
                <a-select
                  id="addLicenseSelect"
                  :placeholder="$t('label.licence')"
                  style="width: 55%;"
                  @change="handleChange"
                  v-else>
                  <a-select-option
                    v-for="tag in selectedTagInfoWithoutNoBillAndOS"
                    :key="tag.key"
                    :disabled="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)">
                    <a-tag style="cursor: not-allowed" v-if="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)" :color="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)? 'grey' : tag.colors">{{ tag.title }}</a-tag>
                    <a-tag v-else style="cursor: pointer" :color="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 ? 'grey' : tag.colors">{{ tag.title }}</a-tag>
                  </a-select-option>
                </a-select>
              <!-- <a-form-item
                v-if="!$route.path.startsWith('/template/')"
                :validate-status="errors.addLicenseInput.status"
                :help="errors.addLicenseInput.message"> -->
                <div
                  class="sliderAndInput"
                  v-if="selectedTagInfo && selectedTagInfo.types === 'Software per Core'">
                  <Slider
                    name="slider"
                    style="width: 55%;"
                    :min="1"
                    :max="resource.cpunumber"
                    v-model:value="addSoftwareLicensesAmount"/>
                  <a-input-number
                    id="coreLicenses"
                    style="width: 40%; margin-left: 20px;"
                    oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                    v-model:value="addSoftwareLicensesAmount"
                    @change="testMinMaxValue(resource.cpunumber)"
                    :maxLength="4"
                    :min="1"
                    :max="resource.cpunumber"
                    required
                  />
                  <span style="margin: 6px 0 0 10px; font-weight: bold;">{{ 'CPUs' }}</span>
                </div>
                <div
                  class="sliderAndInput"
                  v-if="selectedTagInfo && selectedTagInfo.types === 'Software per User'">
                  <Slider
                    name="slider"
                    style="width: 55%;"
                    :min="1"
                    :max="1000"
                    v-model:value="addSoftwareLicensesAmount"/>
                  <a-input-number
                    id="userLicenses"
                    style="width: 40%; margin-left: 20px;"
                    oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                    v-model:value="addSoftwareLicensesAmount"
                    @change="testMinMaxValue(1000)"
                    :maxLength="4"
                    :min="1"
                    :max="1000"
                    required
                  />
                  <span style="margin: 6px 0 0 10px; font-weight: bold;">{{ $t('label.licences') }}</span>
                </div>
             <!--  </a-form-item> -->
            </a-spin>
        </a-modal>
        <!-- Edit License Tags Modal -->
        <a-modal
          :title="$t('label.edit.license')"
          @ok="handleLicenseSubmit('edit')"
          okText="OK"
          cancelText="Cancel"
          :visible="showEditLicenseModal"
          :closable="true"
          @cancel="closeAction"
          centered>
          <!-- <a-form
              v-ctrl-enter="handleLicenseSubmit('edit')"
              :ref="formRef"
              :model="form"
              @finish="handleLicenseSubmit('edit')"
              layout="vertical"
            > -->
            <a-spin :spinning="modalLoading">
              <!-- <a-form-item
                :label="$t('label.edit.license') + ' ' + $t('label.for')"
                :validate-status="errors.editLicenseInput.status"
                :help="errors.editLicenseInput.message"> -->
                <a-tag :color="modalInputTagColor">{{ modalInputTagText }}</a-tag>
                <div
                  v-for="tag in existingTags"
                  :key="tag.key">
                  <div
                    class="sliderAndInput"
                    v-if="tag.key === modalInputTagId && modalInputTagType === 'Software per Core'">
                    <Slider
                      name="slider"
                      style="width: 55%;"
                      :min="1"
                      :max="modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber"
                      v-model:value="currentSoftwareLicenses"/>
                    <a-input-number
                      id="coreLicenses"
                      style="width: 40%; margin-left: 20px;"
                      oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                      v-model:value="currentSoftwareLicenses"
                      @change="testMinMaxValue(modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber)"
                      :maxLength="4"
                      :min="1"
                      :max="modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber"
                      :addonAfter="modalInputTagType === 'Software per User' ? $t('label.licences') : 'CPUs'"
                      required
                    />
                    <span style="margin: 6px 0 0 10px; font-weight: bold;">{{ modalInputTagType === 'Software per User' ? $t('label.licences') : 'CPUs' }}</span>
                  </div>
                </div>
                <div
                  v-for="tag in existingTags"
                  :key="tag.key">
                  <div
                    class="sliderAndInput"
                    v-if="tag.key === modalInputTagId && modalInputTagType === 'Software per User'">
                    <Slider
                      name="slider"
                      style="width: 55%;"
                      :min="1"
                      :max="modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber"
                      v-model:value="currentSoftwareLicenses"/>
                    <a-input-number
                      id="userLicenses"
                      style="width: 40%; margin-left: 20px;"
                      oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                      v-model:value="currentSoftwareLicenses"
                      @change="testMinMaxValue(modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber)"
                      :maxLength="4"
                      :min="1"
                      :max="modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber"
                      required
                    />
                    <span style="margin: 6px 0 0 10px; font-weight: bold;">{{ $t('label.licences') }}</span>
                  </div>
                </div>
            </a-spin>
        </a-modal>
        <!-- stopVmModal -->
        <a-modal
          v-model:visible="showStopVmModal"
          :maskClosable="false"
          :loading="loadingStop"
          centered>
          <template #title>
            {{ $t('label.action.stop.instance') }}
            <a
              style="margin-left: 5px"
              :href="$config.docBase + '/' + ('adminguide/virtual_machines.html#stopping-and-starting-vms' || $route.meta.docHelp)"
              target="_blank">
              <render-icon icon="question-circle-outlined"></render-icon>
            </a>
          </template>
          <template #footer style="text-align: right;">
            <a-button :loading="loadingStop" style="margin-right: 4px" @click="closeAction">{{ this.$t('label.cancel') }}</a-button>
            <a-button :loading="loadingStop" type="primary" @click="handleStopVmSubmit">{{ this.$t('label.ok') }}</a-button>
          </template>
          <a-spin :spinning="modalLoading">
        <span>
          <a-alert type="warning">
            <template #message>{{ $t('message.action.stop.instance') }}</template>
          </a-alert>
          <br>
        </span>
            <a-form :form="form" :loading="loadingStop" layout="vertical">
              <a-form-item>
                <template #label>
                  {{ $t('label.forced') }}
                  <a-tooltip :title="$t('label.force.description')">
                    <render-icon icon="info-circle-outlined" style="color: rgba(0,0,0,.45)" />
                  </a-tooltip>
                </template>
                <span>
              <a-switch v-model:checked="switchIsChecked"></a-switch>
            </span>
              </a-form-item>
            </a-form>
          </a-spin>
        </a-modal>
        <!-- Modal for the StartVM Button Api -->
        <a-modal
          v-model:visible="showStartVMModal"
          :closable="true"
          :footer="null"
          @cancel="closeAction"
          centered>
          <startVirtualMachine
            :resource="resource"
            :fromVpcSettings="true"
            @close-action="closeAction"
            @reloadVpcSettings="fetchData"
          />
        </a-modal>
        <!-- Modal for the DestroyVM Button Api -->
        <a-modal
          v-model:visible="showDestroyVMModal"
          :closable="true"
          :footer="null"
          @cancel="closeAction"
          centered>
          <destroyVM
            :resource="resource"
            @close-action="closeAction"
          />
        </a-modal>
      </div>
      <!-- /WP Implementation -->
      <div>
        <a-row :gutter="12" class='instance-details instance-details-modal'>
          <a-col :md="24" :lg="24">
             <a-card class='instance-details-main'>
               <div class='instance-details-card-header modal-instance-header'>
                 <div class="modal-instance-header-left">
                   <div class="simple-vm-state-container">
                     <div v-if="resource.state !== undefined">
                       <a-tooltip placement="top" >
                         <template #title>
                           <span class="simple-vm-state-text">{{ resource.state || resource.status }}</span>
                         </template>
                         <div class="simple-vm-state running" v-if="resource.state === 'Running'">
                         </div>
                         <div class="simple-vm-state stopped" v-else-if="resource.state === 'Stopped' || resource.state === 'Error'">

                         </div>
                       </a-tooltip>
                     </div>
                     <div v-else-if="resource.podsready">

                       <a-tooltip placement="top" v-if="resource.podsready.split('\u0022')[1] === '0' || resource.podsready.split('\u0022')[1]  === '' || resource.podsready.split('\u0022')[1]  === undefined">
                         <template #title>
                           <span class="simple-vm-state-text">{{ '(' + 0 + '/' + resource.replicacount + 'Replikas)' }}</span>
                         </template>
                         <div class="simple-vm-state stopped"></div>
                       </a-tooltip>

                       <a-tooltip placement="top" v-else-if="resource.podsready.split('\u0022')[1] < resource.replicacount">
                         <template #title>
                           <span class="simple-vm-state-text">{{ '(' + resource.podsready.split('\u0022')[1] + '/' + resource.replicacount + 'Replikas)' }}</span>
                         </template>
                         <div class="simple-vm-state stopped"></div>
                       </a-tooltip>

                       <a-tooltip placement="top" v-else>
                         <template #title>
                           <span class="simple-vm-state-text">{{  'Running (' + resource.podsready.split('\u0022')[1] + '/' + resource.replicacount + 'Replikas)'  }}</span>
                         </template>
                         <div class="simple-vm-state running" ></div>
                       </a-tooltip>

                     </div>
                   </div>
                   <div class='modal-instance-header-name' >{{ resource.name }}</div>
                 </div>
                 <div class="modal-instance-header-right">
                   <a-button
                     v-if="proxyClientList.filter(x => x.vmid === resource.id)[0] === undefined || resource.hasTsPlus === false || !proxyBoolean"
                     :disabled="resource.hasTsPlus === false || !proxyBoolean"
                     @click="addProxyClientToVm(resource)"
                     :loading="fetchProxyClientsLoading"
                     style="margin-left: 1rem;"
                     class="icon-not-white">
                     <template #icon>
                       <RocketOutlined/>
                     </template>
                     {{$t('label.proxy.add.client') }}
                   </a-button>
                   <a-button v-else @click="redirectProxyLink(resource)" :loading="fetchProxyClientsLoading" style="margin-left: 1rem;" class="icon-not-white">
                     <template #icon>
                       <LinkOutlined/>
                     </template>
                     {{ $t('label.proxy.open.client') }}
                   </a-button>

                   <router-link :to="{ path: '/vm/' + resource.id }"  target= '_blank' v-if="resource.zoneid" class='row-action-button-console' >
                     <InfoCircleOutlined />
                   </router-link>
                   <a-tooltip class='row-action-button-console' arrowPointAtCenter placement='bottomRight' v-if='resource && resource.id'>
                     <template #title>
                       {{ $t('label.view.console') }}
                     </template>

                     <a
                       :id="'consoleLink' + this.resource.id"
                       :href="server + '/console?cmd=access&vm=' + resource.id"
                       target="_blank">
    <span v-if="forQuick">
    </span>
                       <a-button
                         v-else
                         style="margin-left: 5px"
                         shape="circle"
                         type="dashed"
                         :size="'default'"
                         :disabled="['Stopped', 'Error', 'Destroyed'].includes(resource.state)" >
                         <code-outlined />
                       </a-button>
                     </a>
                   </a-tooltip>
                   <a-tooltip v-if="resource && resource.id" class='row-action-button-switch-status' arrowPointAtCenter placement='bottomRight'>
                     <template #title>
                       {{ 'VM Starten/Stoppen' }}
                     </template>
                     <a-switch v-model:checked="vmRunning" size="small" @click="toggleVmStatus"/>
                   </a-tooltip>

                 </div>
               </div>
               <div class='instance-details-main-body'>
                 <div class='instance-details-os-icon'>
                 <span v-if="(resource.icon && resource.icon.base64image || images.template || images.iso || resourceIcon) && !['router', 'systemvm', 'volume'].includes($route.path.split('/')[1])">
                  <resource-icon :image="getImage(resource.icon && resource.icon.base64image || images.template || images.iso || resourceIcon)" size="4x" style="margin-right: 5px"/>
                </span>
                   <span v-else>
                  <os-logo v-if="resource.ostypeid || resource.ostypename" :osId="resource.ostypeid" :osName="resource.ostypename" size="4x" @update-osname="setResourceOsType"/>
                  <render-icon v-else-if="typeof $route.meta.icon ==='string'" style="font-size: 36px" :icon="$route.meta.icon" />
                  <render-icon v-else style="font-size: 36px" :svgIcon="$route.meta.icon" />
                </span>
                   <a-tooltip placement="bottom">
                     <template #title>
                       {{ resource.ostypename }}
                     </template>
                   <span class='instance-details-os-name'>{{resource.ostypename}}</span>
                   </a-tooltip>
                 </div>
                <div class="">
                  <div class='instance-details-info'>
                    <div class='instance-details-info-item'  v-if="('cpunumber' in resource && 'cpuspeed' in resource) || resource.cputotal">
                      <img src="../../assets/icons-cloud/cpu.png" >
                      <div class='instance-details-info-item-text'>
                        <span>{{ (resource.cpunumber || resource.cputotal) === 1 ? (resource.cpunumber || resource.cputotal)  : (resource.cpunumber || resource.cputotal) }}</span>
                        <span class='instance-details-info-item-text-small'>{{ $t('label.cpu') }}</span>
                      </div>
                    </div>
                    <div class='instance-details-info-item'  v-if="'memory' in resource">
                      <img src="../../assets/icons-cloud/ram.png" >
                      <div class='instance-details-info-item-text'>
                        <span>{{ (resource.memory / 1024) + ' GB '}}</span>
                        <span class='instance-details-info-item-text-small'>{{ $t('label.memory') }}</span>
                      </div>
                    </div>
                    <div class='instance-details-info-item'  v-else-if="resource.memorytotalgb">
                      <img src="../../assets/icons-cloud/ram.png" >
                      <div class='instance-details-info-item-text'>
                        {{ resource.memorytotalgb  }}
                        <span class='instance-details-info-item-text-small'>{{ $t('label.memory') }}</span>
                      </div>
                    </div>
                    <div class='instance-details-info-item'  v-else-if="resource.memorytotal">
                      <img src="../../assets/icons-cloud/ram.png" >
                      <div class='instance-details-info-item-text'>
                        {{ resource.memorytotal }}
                        <span class='instance-details-info-item-text-small'>{{ $t('label.memory') }}</span>
                      </div>
                    </div>
                    <div class='instance-details-info-item'  v-if="resource.volumes || resource.volumeSizeSummed">
                      <img src="../../assets/icons-cloud/disk.png" >
                      <div class='instance-details-info-item-text'>
                        <span style="width: 100%;" v-if="$route.meta.name === 'vm' && resource.volumes">{{ (resource.volumes.reduce((total, item) => total += item.size, 0) / (1024 * 1024 * 1024.0)).toFixed(2) }} GB</span>
                        <span style="width: 100%;" v-else-if="resource.volumeSizeSummed || resource.size">{{ resource.volumeSizeSummed || (resource.size/1024.0) }}</span>

                        <span class='instance-details-info-item-text-small'>{{ $t('label.disksize') }}</span>
                      </div>
                    </div>
                    <div class='instance-details-info-item'  v-else-if="resource.disksizetotalgb">
                      <img src="../../assets/icons-cloud/disk.png" >
                      <div class='instance-details-info-item-text'>
                        <span>{{ resource.disksizetotalgb }}</span>
                        <span class='instance-details-info-item-text-small'>{{ $t('label.disksize') }}</span>
                      </div>
                    </div>
                    <div class='instance-details-info-item'  v-if=" ('networkkbsread' in resource && 'networkkbswrite' in resource)">
                      <img src="../../assets/icons-cloud/traffic.png" >
                      <div class='instance-details-info-item-text'>
                        RX {{ toSize(resource.networkkbsread) }}/TX {{ toSize(resource.networkkbswrite) }}
                        <span class='instance-details-info-item-text-small'>{{ 'Netzwerk' }}</span>
                      </div>
                    </div>
                    <div class='instance-details-info-item'>
                      <img src="../../assets/icons-cloud/vgpu.png" >
                      <div class='instance-details-info-item-text'>
                        <span>{{ vgpuState }}</span>
                        <span class='instance-details-info-item-text-small'>{{ $t('label.vgpu') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class='instance-details-main-lizenz'>
                    <a-spin :spinning="licenseLoading">
                      <div style="display: flex;">
                        <a-tooltip placement="top">
                          <template #title>
                            {{ $t('label.add.license') }}
                          </template>
                          <a-button
                            style="margin: 0 5px 5px 0;"
                            shape="circle"
                            size="small"
                            @click="openAddVMLicensesModal">
                            <template #icon>
                              <PlusOutlined/>
                            </template>
                          </a-button>
                        </a-tooltip>
                        <a-collapse :bordered="true" accordion>
                          <a-collapse-panel key="1" :header="$t('label.license.click.to.show') + ' >'">
                            <div class="resource-detail-item__details" v-if="vmLicenceTags.length >= 1">
                              <div style="display: flex; flex-direction: column; margin-top: 8px;">
                                <div
                                  v-for="tag in vmLicenceTags"
                                  :key="tag.tagText">
                                  <div
                                    v-for="taglicenses in nrOfSoftwareLicenses"
                                    :key="taglicenses.tagID">
                                    <!-- If there is ever a new License Type needed, add it here! -->
                                    <!-- Case for all Licenses Per User -->
                                    <div v-if="tag.tagType === 'Software per User' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                      <FileDoneOutlined />
                                      <a-tag class="tagToRight" style="margin-bottom: 2px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                      <!-- Show Buttons for deleting and editing software licenses -->
                                      <a-tooltip placement="top">
                                        <template #title>
                                          {{ $t('label.remove.license') }}
                                        </template>
                                        <a-button
                                          v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                          style="margin-right: 4px;"
                                          type="primary"
                                          shape="circle"
                                          size="small"
                                          danger
                                          @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                          <template #icon>
                                            <DeleteOutlined/>
                                          </template>
                                        </a-button>
                                      </a-tooltip>
                                      <a-tooltip placement="top">
                                        <template #title>
                                          {{ $t('label.edit.license') }}
                                        </template>
                                        <a-button
                                          v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                          style="margin-right: 4px;"
                                          shape="circle"
                                          size="small"
                                          @click="openEditVMLicensesModal(tag.tagText, tag.tagColor, tag.tagType, tag.id, taglicenses.licenseCounter)">
                                          <template #icon>
                                            <SettingOutlined/>
                                          </template>
                                        </a-button>
                                      </a-tooltip>
                                      <br>
                                      <!-- this is the Amount and price for User Licenses -->
                                      <span style="margin-left: 1.5rem;">{{ taglicenses.licenseCounter }} User x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(taglicenses.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                                    </div>
                                    <!-- Case for all OS licenses (Should always only be one though) -->
                                    <div v-if="tag.tagType === 'OS' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                      <FileDoneOutlined />
                                      <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                      <!-- only show delete OS Button if User = Admin -->
                                      <a-button
                                        v-if="tag.tagType === 'OS' && ['Admin'].includes($store.getters.userInfo.roletype)"
                                        style="margin-right: 4px;"
                                        type="primary"
                                        shape="circle"
                                        size="small"
                                        danger
                                        @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                        <template #icon>
                                          <DeleteOutlined/>
                                        </template>
                                      </a-button>
                                      <br>
                                      <!-- this is the Amount and price for OS Licenses -->
                                      <span style="margin-left: 1.5rem;">{{ resource.cpunumber || resource.cputotal }} {{ (resource.cpunumber || resource.cputotal) === 1 ? 'CPU' : 'CPUs' }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(resource.cpunumber * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                                    </div>
                                    <!-- Case for all Hardware licenses (GPU) -->
                                    <div v-if="tag.tagType === 'Hardware' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                      <FileDoneOutlined />
                                      <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                      <br>
                                      <!-- this is the Amount and price for Hardware (GPU) Licenses -->
                                      <span v-if="tag.tagText !== 'GPU'" style="margin-left: 1.5rem;">{{ taglicenses.licenseCounter + ' GPU '}} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(taglicenses.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                                    </div>
                                    <!-- Case for all Fixed Price licenses -->
                                    <div v-if="tag.tagType === 'Fixed Price' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                      <FileDoneOutlined />
                                      <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                      <a-button
                                        v-if="tag.tagType === 'Fixed Price' && ['Admin'].includes($store.getters.userInfo.roletype)"
                                        style="margin-right: 4px;"
                                        type="primary"
                                        shape="circle"
                                        size="small"
                                        danger
                                        @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                        <template #icon>
                                          <DeleteOutlined/>
                                        </template>
                                      </a-button>
                                      <br>
                                      <!-- this is the Amount and price for Hardware (GPU) Licenses -->
                                      <span style="margin-left: 1.5rem;">{{ taglicenses.licenseCounter + ' '}} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(taglicenses.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                                    </div>
                                    <!-- Case for all Software Package licenses -->
                                    <div v-if="tag.tagType === 'Software Package' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                      <FileDoneOutlined />
                                      <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                      <!-- only show delete OS Button if User = Admin -->
                                      <a-button
                                        v-if="tag.tagType === 'Software Package'"
                                        style="margin-right: 4px;"
                                        type="primary"
                                        shape="circle"
                                        size="small"
                                        danger
                                        @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                        <template #icon>
                                          <DeleteOutlined/>
                                        </template>
                                      </a-button>
                                      <br>
                                      <!-- this is the Price for Software Package Licenses -->
                                      <span style="margin-left: 1.5rem;">{{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                                    </div>
                                    <!-- Case for all licenses per COre -->
                                    <div v-if="tag.tagType === 'Software per Core' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                      <FileDoneOutlined />
                                      <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                      <!-- Show Buttons for deleting and editing software licenses -->
                                      <a-tooltip placement="top">
                                        <template #title>
                                          {{ $t('label.remove.license') }}
                                        </template>
                                        <a-button
                                          v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                          style="margin-right: 4px;"
                                          type="primary"
                                          shape="circle"
                                          size="small"
                                          danger
                                          @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                          <template #icon>
                                            <DeleteOutlined/>
                                          </template>
                                        </a-button>
                                      </a-tooltip>
                                      <a-tooltip placement="top">
                                        <template #title>
                                          {{ $t('label.edit.license') }}
                                        </template>
                                        <a-button
                                          v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                          style="margin-right: 4px;"
                                          shape="circle"
                                          size="small"
                                          @click="openEditVMLicensesModal(tag.tagText, tag.tagColor, tag.tagType, tag.id, taglicenses.licenseCounter)">
                                          <template #icon>
                                            <SettingOutlined/>
                                          </template>
                                        </a-button>
                                      </a-tooltip>
                                      <br>
                                      <!-- this is the Amount and price for Core Licenses -->
                                      <span style="margin-left: 1.5rem;">{{ taglicenses.licenseCounter + ' ' }}{{ taglicenses.licenseCounter === 1 ? $t('label.metrics.num.cpu.core') : $t('label.metrics.num.cpu.cores') }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(taglicenses.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                                    </div>
                                    <!-- Case for noBill licenses -->
                                    <div v-if="tag.tagType === 'NoBill' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                      <FileDoneOutlined />
                                      <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                      <!-- only show delete OS Button if User = Admin -->
                                      <a-button
                                        v-if="tag.tagType === 'NoBill'"
                                        style="margin-right: 4px;"
                                        type="primary"
                                        shape="circle"
                                        size="small"
                                        danger
                                        @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                        <template #icon>
                                          <DeleteOutlined/>
                                        </template>
                                      </a-button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- if no licenses are associated with the current VM -->
                            <div v-else>
                              {{ $t('message.no.associated.licenses') }}
                            </div>
                          </a-collapse-panel>
                        </a-collapse>
                      </div>
                    </a-spin>
                  </div>
                  <div class='instance-details-main-id'>
                    <span><img src="../../assets/icons-cloud/barcode.png" > {{resource.id}}</span>
                  </div>
                </div>
               </div>
               <div class='instance-details-main-footer'>
                 <div class='instance-details-list'>
                   <div class='instance-details-list-item' v-if="$route.meta.name !== 'zone' && $route.path.startsWith('/vm/')">
                     <div class='instance-details-list-item-title'>
                       {{ 'Cyber Cloud Status' }}
                     </div>
                     <div class='instance-details-list-item-value'>
                       <img class="shieldIcon" v-if="acronisVmPbsIsActivated" v-bind:src="require('../../assets/icons-cloud/firewall-on.png')"/>
                       <img class="shieldIcon" v-else v-bind:src="require('../../assets/icons-cloud/firewall-off.png')">
                       <span>{{ acronisVmPbsIsActivated ? $t('label.enabled') : $t('label.disabled') }}</span>
                     </div>
                   </div>
                   <div class='instance-details-list-item' v-if="resource.templateid">
                     <div class='instance-details-list-item-title'>
                       {{ $t('label.templatename') }}
                     </div>
                     <div class='instance-details-list-item-value'>
                       <img src="../../assets/icons-cloud/camera.png" >
                       <div>
                         <router-link :to="{ path: '/template/' + resource.templateid }">{{ resource.templatedisplaytext || resource.templatename || resource.templateid }} </router-link>
                       </div>
                     </div>
                   </div>
                   <div class='instance-details-list-item' v-if="resource.isoid">
                     <div class='instance-details-list-item-title'>
                       {{ $t('label.iso') }}
                     </div>
                     <div class='instance-details-list-item-value'>
                       <img src="../../assets/icons-cloud/camera.png" >
                       <div v-if="resource.isoid">
                         <router-link :to="{ path: '/iso/' + resource.isoid }">{{ resource.isodisplaytext || resource.isoname || resource.isoid }} </router-link>
                       </div>
                       <div v-else>
                         <router-link :to="{ path: '/template/' + resource.templateid }">{{ resource.templatedisplaytext || resource.templatename || resource.templateid }} </router-link>
                       </div>
                     </div>
                   </div>
                   <div class='instance-details-list-item' v-if="resource.serviceofferingname && resource.serviceofferingid">

                     <div class='instance-details-list-item-title'>
                       {{ $t('label.serviceofferingname') }}
                     </div>
                     <div class='instance-details-list-item-value'>
                       <img src="../../assets/icons-cloud/cloud.png" >
                       <router-link v-if="!isStatic && $route.meta.name === 'router'" :to="{ path: '/computeoffering/' + resource.serviceofferingid, query: { issystem: true } }">{{ resource.serviceofferingname || resource.serviceofferingid }} </router-link>
                       <router-link v-else-if="$router.resolve('/computeoffering/' + resource.serviceofferingid).matched[0].redirect !== '/exception/404'" :to="{ path: '/computeoffering/' + resource.serviceofferingid }">{{ resource.serviceofferingname || resource.serviceofferingid }} </router-link>
                       <span v-else>{{ resource.serviceofferingname || resource.serviceofferingid }}</span>
                     </div>
                   </div>
                   <div class='instance-details-list-item' v-if="resource.zoneid">
                     <div class='instance-details-list-item-title'>
                       {{ $t('label.zone') }}
                     </div>
                     <div class='instance-details-list-item-value'>
                       <global-outlined  />
                       <router-link v-if="!isStatic && $router.resolve('/zone/' + resource.zoneid).matched[0].redirect !== '/exception/404'" :to="{ path: '/zone/' + resource.zoneid }">{{ resource.zone || resource.zonename || resource.zoneid }}</router-link>
                       <span v-else>{{ resource.zone || resource.zonename || resource.zoneid }}</span>
                     </div>
                   </div>
                   <div class='instance-details-list-item' v-if="resource.owner">
                     <div class='instance-details-list-item-title'>
                       {{ $t('label.owners') }}
                     </div>
                     <div class='instance-details-list-item-value'>
                       <user-outlined />
                       <template v-for="(item, idx) in resource.owner" :key="idx">
              <span style="margin-right:5px">
                <span v-if="$store.getters.userInfo.roletype !== 'User'">
                  <router-link v-if="!isStatic && 'user' in item" :to="{ path: '/accountuser', query: { username: item.user, domainid: resource.domainid }}">{{ item.account + '(' + item.user + ')' }}</router-link>
                  <router-link v-else :to="{ path: '/account', query: { name: item.account, domainid: resource.domainid } }">{{ item.account }}</router-link>
                </span>
                <span v-else>{{ item.user ? item.account + '(' + item.user + ')' : item.account }}</span>
              </span>
                       </template>
                     </div>
                   </div>
                   <div class="instance-details-list-item" v-if="resource.domainid">
                     <div class="instance-details-list-item-title">{{ $t('label.domain') }}</div>
                     <div class="instance-details-list-item-value">
                       <resource-icon v-if="images.domain" :image="getImage(images.domain)" size="1x" style="margin-right: 5px"/>
                       <block-outlined v-else />
                       <router-link v-if="!isStatic && $store.getters.userInfo.roletype !== 'User'" :to="{ path: '/domain/' + resource.domainid, query: { tab: 'details'}  }">{{ resource.domain || resource.domainid }}</router-link>
                       <span v-else>{{ resource.domain || resource.domainid }}</span>
                     </div>
                   </div>
                   <div class="instance-details-list-item" v-if="resource.managementserverid">
                     <div class="instance-details-list-item-title">{{ $t('label.management.servers') }}</div>
                     <div class="instance-details-list-item-value">
                       <rocket-outlined />
                       <router-link v-if="!isStatic && $router.resolve('/managementserver/' + resource.managementserverid).matched[0].redirect !== '/exception/404'" :to="{ path: '/managementserver/' + resource.managementserverid }">{{ resource.managementserver || resource.managementserverid }}</router-link>
                       <span v-else>{{ resource.managementserver || resource.managementserverid }}</span>
                     </div>
                   </div>
                   <div class="instance-details-list-item" v-if="resource.created">
                     <div class="instance-details-list-item-title">{{ $t('label.created') }}</div>
                     <div class="instance-details-list-item-value">
                       <calendar-outlined />{{ $toLocaleDate(resource.created) }}
                     </div>
                   </div>
                   <div class="instance-details-list-item" v-if="resource.lastupdated">
                     <div class="instance-details-list-item-title">{{ $t('label.last.updated') }}</div>
                     <div class="instance-details-list-item-value">
                       <calendar-outlined />{{ $toLocaleDate(resource.lastupdated) }}
                     </div>
                   </div>
                 </div>
               </div>
             </a-card>
          </a-col>
          <a-col :md="24" :lg="24">
            <a-card class='instance-details-card'>
              <div class='instance-details-card-header'>
                <img src="../../assets/icons-cloud/network.png" >
                {{ $t('label.network') }}
              </div>
              <div class='instance-details-card-body'>
                <ul class='network-list' v-if='resource.nic'>
                  <li>
                    <img src='../../assets/icons-cloud/wlan.png'>
                    <div v-if="'networkkbsread' in resource && 'networkkbswrite' in resource">
                      <ArrowDownOutlined />RX {{ toSize(resource.networkkbsread) }}
                      &nbsp;&nbsp;&nbsp;&nbsp;<ArrowUpOutlined />TX {{ toSize(resource.networkkbswrite) }}
                    </div>
                    <div v-else>{{ resource.nic.length }} NIC(s)</div>
                  </li>
                  <li
                    v-if='resource.nic.length > 0'
                    v-for="(eth, index) in resource.nic"
                    :key="eth.id">
                    <img src='../../assets/icons-cloud/link.png'>
                    <div>
                    <strong>eth{{ index }}</strong> {{ eth.ip6address ? eth.ipaddress + ', ' + eth.ip6address : eth.ipaddress }}
                   <br> <router-link v-if="!isStatic && eth.networkname && eth.networkid" :to="{ path: '/guestnetwork/' + eth.networkid }">({{ eth.networkname }})</router-link>
                    <span v-if="eth.isdefault">
                      {{ $t('label.default') }}
                    </span >
                    </div>
                  </li>
                </ul>
              </div>
            </a-card>
          </a-col>

        </a-row>
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import { api } from '@/api'
import Console from '@/components/widgets/Console'
import OsLogo from '@/components/widgets/OsLogo'
import Status from '@/components/widgets/Status'
import TooltipButton from '@/components/widgets/TooltipButton'
import UploadResourceIcon from '@/components/view/UploadResourceIcon'
import eventBus from '@/config/eventBus'
import ResourceIcon from '@/components/view/ResourceIcon'
import ResourceLabel from '@/components/widgets/ResourceLabel'
/* WP Implementation */
import wpapi from '../../wpApi/myApi'
import api2 from '@/wpApi/api2'
import { Icon, Popover, Slider } from 'ant-design-vue'
import { LaptopOutlined, FileDoneOutlined } from '@ant-design/icons-vue'
import ActionButton from '@/components/view/ActionButton.vue'
import { reactive, ref } from 'vue'
import DestroyVM from '@/views/compute/DestroyVM.vue'
import startVirtualMachine from '@/views/compute/StartVirtualMachine.vue'
import { SERVER_MANAGER } from '@/store/mutation-types'

export default {
  name: 'ModalInstanceInfoCard',
  components: {
    startVirtualMachine,
    DestroyVM,
    Popover,
    Console,
    OsLogo,
    Status,
    TooltipButton,
    UploadResourceIcon,
    ResourceIcon,
    ResourceLabel,
    Icon,
    Slider,
    ActionButton,
    LaptopOutlined,
    FileDoneOutlined
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    bordered: {
      type: Boolean,
      default: true
    },
    isStatic: {
      type: Boolean,
      default: false
    },
    vpcOwnerAccUuid: {
      type: String
    }
  },
  data () {
    return {
      proxyBoolean: false,
      fetchProxyClientsLoading: false,
      proxyDomain: undefined,
      proxyId: String,
      proxyClientList: [{
        vmid: '555a493b-61a4-4507-baa7-96bc3a8c34f3'
      }],
      showStartVMModal: false,
      showStopVmModal: false,
      tag: '',
      ipaddress: '',
      resourceType: '',
      inputVisible: false,
      inputKey: '',
      inputValue: '',
      tags: [],
      switchIsChecked: false,
      showKeys: false,
      loadingTags: false,
      showUpload: false,
      loadingStop: false,
      images: {
        zone: '',
        template: '',
        iso: '',
        domain: '',
        account: '',
        project: '',
        vpc: '',
        network: ''
      },
      newResource: {},

      // WP Implementation
      vgpuState: '',
      subAccountUuid: '',
      acronisVmPbsIsActivated: false,
      acronisTenantIdCheck: /^[A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12}$/,
      accountUuid: this.$store.getters.userInfo.accountid,
      accountName: this.$store.getters.userInfo.account,
      vmLicenceTags: [],
      discountList: [],
      showAddLicenseModal: false,
      showEditLicenseModal: false,
      showDeleteLicenseModal: false,
      modalInputTagColor: String,
      modalInputTagText: String,
      modalInputTagType: String,
      modalInputTagId: String,
      existingTags: [],
      templateTags: [],
      selectedTag: [],
      tagReservedForAdmin: false,
      addSoftwareLicensesAmount: 1,
      modalLoading: false,
      licenseLoading: false,
      // this data param is the actual number of defined userLicenses for a allready existing VM
      nrOfSoftwareLicenses: {},
      submitTagInput: 0,
      currentSoftwareLicenses: 1,
      errors: {
        addLicenseInput: {
          status: '',
          message: ''
        },
        editLicenseInput: {
          status: '',
          message: ''
        }
      }
    }
  },
  // /WP Implementation
  watch: {
    '$route.fullPath': function (path) {
      if (path === '/user/login') {
        return
      }
      this.getIcons()
    },
    resource: {
      deep: true,
      async handler (newData, oldData) {
        if (newData === oldData) return
        this.newResource = newData
        this.showKeys = false
        this.setData()
        // WP Implementation
        this.fetchVgpu()
        if (this.$route.path.startsWith('/vm/')) {
          this.fetchAcronisVmStatus()
          await this.getTagsFromVm(this.resource.id)
          this.fetchNrOfSoftwareLicencesAllVm(this.resource.id)
          this.getAllTagsApi()
        }
        if (this.$route.path.startsWith('/template/')) {
          this.getTemplateLicenseTagInfos()
          this.getAllTagsApi()
        }
        if (this.$route.path.startsWith('/volume/') && this.resource.virtualmachineid) {
          this.getTagsFromVm(this.resource.virtualmachineid)
          /* this.fetchNrOfSoftwareLicencesAllVm(this.resource.virtualmachineid)
          this.getAllTagsApi() */
        }
        // /WP Implementation
        if (this.tagsSupportingResourceTypes.includes(this.resourceType)) {
          if ('tags' in this.resource) {
            this.tags = this.resource.tags
          } else if (this.resourceType) {
            this.getTags()
          }
        }
        if ('apikey' in this.resource) {
          this.getUserKeys()
        }
        this.updateResourceAdditionalData()
        // WP Implementation
        this.fetchResellerCustomerAccounts()
        // /WP Implementation
      }
    },
    async templateIcon () {
      this.getIcons()
    },
    // WP Implementation
    nrOfSoftwareLicenses: function (newItem, oldItem) {
      if (newItem !== oldItem) {
        this.$forceUpdate()
      }
    },
    vmLicenceTags: function (newItem, oldItem) {
      if (newItem !== oldItem) {
        this.$forceUpdate()
      }
    }
  },
  // /WP Implementation
  async created () {
    this.initForm()
    this.setData()
    eventBus.on('handle-close', (showModal) => {
      this.showUploadModal(showModal)
    })
    this.updateResourceAdditionalData()
    await this.getIcons()
  },
  computed: {
    selectedTagInfoWithoutNoBill () {
      const tagInfo = this.existingTags.filter(tag => tag.types !== 'NoBill')
      return tagInfo
    },
    selectedTagInfoWithoutNoBillAndOS () {
      const tagInfo = this.existingTags.filter(tag => tag.types !== 'NoBill' && tag.types !== 'OS' && tag.types !== 'Hardware')
      return tagInfo
    },
    selectedTagInfo () {
      const tagInfo = this.existingTags.filter(tag => tag.key === this.selectedTag)[0]
      return tagInfo === undefined ? undefined : tagInfo
    },
    tagsSupportingResourceTypes () {
      return ['UserVm', 'Template', 'ISO', 'Volume', 'Snapshot', 'Backup', 'Network',
        'LoadBalancer', 'PortForwardingRule', 'FirewallRule', 'SecurityGroup', 'SecurityGroupRule',
        'PublicIpAddress', 'Project', 'Account', 'Vpc', 'NetworkACL', 'StaticRoute', 'VMSnapshot',
        'RemoteAccessVpn', 'User', 'SnapshotPolicy', 'VpcOffering']
    },
    name () {
      return this.resource.displayname || this.resource.displaytext || this.resource.name || this.resource.username ||
        this.resource.ipaddress || this.resource.virtualmachinename || this.resource.templatetype
    },
    keypairs () {
      if (!this.resource.keypairs) {
        return null
      }
      if (typeof this.resource.keypairs === 'string' || this.resource.keypairs instanceof String) {
        return this.resource.keypairs.split(',')
      }
      return [this.resource.keypairs.toString()]
    },
    templateIcon () {
      return this.resource.templateid
    },
    resourceIcon () {
      if (this.$showIcon() && this.resource?.icon?.base64image) {
        return this.resource.icon.base64image
      }
      return null
    },
    routeFromResourceType () {
      return this.$getRouteFromResourceType(this.resource.resourcetype)
    },
    vmRunning () {
      if ('state' in this.resource) {
        return this.resource.state === 'Running'
      }
      return false
    },
    server () {
      if (!this.$config.multipleServer) {
        return this.$config.apiBase.replace('/api', '')
      }
      const serverStorage = this.$localStorage.get(SERVER_MANAGER)
      const apiBase = serverStorage.apiBase.replace('/api', '')
      if (!serverStorage.apiHost || serverStorage.apiHost === '/') {
        return [location.origin, apiBase].join('')
      }
      return [serverStorage.apiHost, apiBase].join('')
    }
  },
  async mounted () {
    this.getIcons()
    await this.fetchProxyData()
    if (this.proxyBoolean) {
      await this.fetchProxyClients()
    }
    // WP Implementation
    if (this.$route.path.startsWith('/action/deployVirtualMachine') || this.$route.path.startsWith('/vm') || this.$route.path.startsWith('/snapshot') || this.$route.path.startsWith('/volume')) {
      if (!['Admin'].includes(this.$store.getters.userInfo.roletype)) {
        await this.getDiscountList()
      }
    }
    // /WP Implementation
  },
  methods: {
    fetchVgpu () {
      var vgpuInput = new FormData()
      vgpuInput.append('vmuuid', this.resource.id)
      api2.getAllocatedGpu(vgpuInput).then(response => {
        if (response.data !== false) {
          this.vgpuState = response.data.manufacturer + ' ' + response.data.model
        } else {
          this.vgpuState = this.$t('label.vgpu.not.available')
        }
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('label.error'),
          description: 'Error fetching GPUs for this VM'
        })
        this.vgpuState = this.$t('label.vgpu.not.available')
      })
    },
    updateResourceAdditionalData () {
      if (!this.resource) return
      this.resourceType = this.$route.meta.resourceType
      if (this.tagsSupportingResourceTypes.includes(this.resourceType)) {
        if ('tags' in this.resource) {
          this.tags = this.resource.tags
        } else if (this.resourceType) {
          this.getTags()
        }
      }
      this.getIcons()
    },
    initForm () {
      this.formRefAddLicenseTag = ref()
      this.formAddLicenseTag = reactive({
        tag: undefined,
        tagColor: undefined
      })
      this.addLicenseTagRules = reactive({
        tag: [{ required: true, message: this.$t('label.required') }]
      })
    },
    async fetchAcronisVmStatus () {
      var getUserParams = new FormData()
      getUserParams.append('vmid', this.resource.id)
      await api2.getAcronisVM(getUserParams).then(response => {
        if (response.data && response.data.status === 'error') {
          this.acronisVmPbsIsActivated = false
        } else if (response.data && response.data.id && this.acronisTenantIdCheck.test(response.data.id)) {
          this.acronisVmPbsIsActivated = true
        } else {
          this.acronisVmPbsIsActivated = false
          console.log('Failed to request Acronis VM Status')
        }
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    // /WP Implementation
    showUploadModal (show) {
      if (show) {
        if (this.$showIcon()) {
          this.showUpload = true
        }
      } else {
        this.showUpload = false
      }
    },
    getImage (image) {
      return (image || this.resource?.icon?.base64image)
    },
    async getIcons () {
      this.images = {
        zone: '',
        template: '',
        iso: '',
        domain: '',
        account: '',
        project: '',
        vpc: '',
        network: ''
      }
      if (this.resource.templateid) {
        await this.fetchResourceIcon(this.resource.templateid, 'template')
      }
      if (this.resource.isoid) {
        await this.fetchResourceIcon(this.resource.isoid, 'iso')
      }
      if (this.resource.zoneid) {
        await this.fetchResourceIcon(this.resource.zoneid, 'zone')
      }
      if (this.resource.domainid) {
        await this.fetchResourceIcon(this.resource.domainid, 'domain')
      }
      if (this.resource.account) {
        await this.fetchAccount()
      }
      if (this.resource.projectid) {
        await this.fetchResourceIcon(this.resource.projectid, 'project')
      }
      if (this.resource.vpcid) {
        await this.fetchResourceIcon(this.resource.vpcid, 'vpc')
      }
      if (this.resource.networkid) {
        await this.fetchResourceIcon(this.resource.networkid, 'network')
      }
    },
    fetchAccount () {
      return new Promise((resolve, reject) => {
        api('listAccounts', {
          name: this.resource.account,
          domainid: this.resource.domainid,
          showicon: true
        }).then(async json => {
          const response = json?.listaccountsresponse?.account || []
          if (response?.[0]?.icon) {
            this.images.account = response[0].icon.base64image
          }
        })
      })
    },
    fetchResourceIcon (resourceid, type) {
      if (resourceid) {
        return new Promise((resolve, reject) => {
          api('listResourceIcon', {
            resourceids: resourceid,
            resourcetype: type
          }).then(json => {
            const response = json.listresourceiconresponse.icon || []
            if (response?.[0]) {
              this.images[type] = response[0].base64image
              resolve(this.images)
            } else {
              this.images[type] = ''
              resolve(this.images)
            }
          }).catch(error => {
            reject(error)
          })
        })
      } else {
        this.images.type = ''
      }
    },
    // WP Implementation
    testMinMaxValue (max) {
      if (this.showAddLicenseModal && max) {
        if (this.addSoftwareLicensesAmount > max) {
          this.addSoftwareLicensesAmount = max
        } else if (this.addSoftwareLicensesAmount < 1) {
          this.addSoftwareLicensesAmount = 1
        }
      } else {
        var maxValue = this.modalInputTagType === 'Software per User' ? 1000 : this.resource.cpunumber
        if (this.showEditLicenseModal) {
          if (this.currentSoftwareLicenses < 1) {
            this.currentSoftwareLicenses = 1
          } else if (this.currentSoftwareLicenses > maxValue) {
            this.currentSoftwareLicenses = maxValue
          }
        }
      }
    },
    handleChange (value) {
      this.addSoftwareLicensesAmount = 1
      // set addSoftwareLicenses to 'noValue' if OS Tag is selected
      if (this.existingTags.filter(x => x.key === value)[0].types === 'OS') {
        this.addSoftwareLicensesAmount = 'noValue'
      // set addSoftwareLicenses to 1 if Software Package Tag is selected
      } else if (this.existingTags.filter(x => x.key === value)[0].types === 'Software Package') {
        this.addSoftwareLicensesAmount = 1
      }
      this.selectedTag = value
      this.submitTagInput = value
      this.errors.addLicenseInput.status = ''
      this.errors.addLicenseInput.message = ''
    },
    // get ALL possible Tags from the Tag DB
    getAllTagsApi () {
      wpapi.getAllTags().then(response => {
        var allTags = response.data
        var tempTransferInput = []
        for (var tag in allTags) {
          const data = {
            key: allTags[tag].id,
            title: allTags[tag].tagText,
            colors: allTags[tag].tagColor,
            types: allTags[tag].tagType
          }
          tempTransferInput.push(data)
        }
        this.existingTags = tempTransferInput
      })
    },
    getTemplateLicenseTagInfos () {
      this.licenseLoading = true
      wpapi.fetchTemplateLicenseTags(this.resource.id).then(response => {
        this.templateTags = response.data
      })
        .catch(error => {
          this.$notifyError(error)
        })
      this.licenseLoading = false
    },
    openDeleteVMLicenseModal (tagName, tagColor, tagId) {
      this.modalInputTagColor = tagColor
      this.modalInputTagText = tagName
      this.modalInputTagId = tagId
      this.showDeleteLicenseModal = true
    },
    openEditVMLicensesModal (tagName, tagColor, tagType, tagId, currentLicenses) {
      this.currentSoftwareLicenses = currentLicenses
      this.modalInputTagColor = tagColor
      this.modalInputTagText = tagName
      this.modalInputTagId = tagId
      this.modalInputTagType = tagType
      this.showEditLicenseModal = true
    },
    openAddVMLicensesModal () {
      this.showAddLicenseModal = true
    },
    // WP Implementation
    async fetchResellerCustomerAccounts () {
      if (this.resource.account && this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.$route.path.startsWith('/vm/')) {
        if (this.resource.account !== this.accountName) {
          wpapi.getResellerSubAccounts(this.accountUuid).then(response => {
            this.subAccountUuid = response.data.filter(x => x.accountName === this.resource.account)[0].uuid
          }).catch(error => {
            console.log(error)
          })
        }
      }
    },
    // /WP Implementation
    async handleLicenseSubmit (submitType) {
      // if user === ResellerAdmin
      var params = new FormData()
      switch (submitType) {
        case 'add':
          if (!this.resource.templatetype) {
            if (!this.validateInput('addLicenseInput', this.addSoftwareLicensesAmount)) {
              return
            }
          }
          this.modalLoading = true
          if (['Admin'].includes(this.$store.getters.userInfo.roletype)) {
            // Case if admin changes VM Licenses
            // if admin adds to template
            if (this.resource.templatetype) {
              params.append('tagId', this.submitTagInput)
              params.append('templateUUID', this.resource.id)
              wpapi.addLicenseTagToTemplate(params).then(response => {
              }).catch(error => {
                this.$notifyError(error)
              })
              break
            } else {
              // if resource is VM
              params.append('accountName', this.resource.account)
              if (this.addSoftwareLicensesAmount === 'noValue') {
                params.append('counter', 0)
                params.append('tagId', this.submitTagInput)
                params.append('templateId', this.resource.templateid)
                params.append('vmUUID', this.resource.id)
              } else {
                params.append('counter', this.addSoftwareLicensesAmount)
                params.append('tagId', this.submitTagInput)
                params.append('templateId', this.resource.templateid)
                params.append('vmUUID', this.resource.id)
              }
              // add other API here
              await wpapi.createTagEntryForVmByAdmin(params).then(response => {
              }).catch(error => {
                this.$notifyError(error)
              })
              break
            }
          } else {
            // Case if User adds VM Licenses
            // if logged in user is a ResellerAdmin, use Account uuid of the VM Owner instead of accountUuid of the Account that is logged in currently
            if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.resource.account !== this.accountName) {
              params.append('accountUuid', this.subAccountUuid)
            } else { params.append('accountUuid', this.accountUuid) }
            if (this.addSoftwareLicensesAmount === 'noValue') {
              params.append('counter', 0)
              params.append('tagId', this.submitTagInput)
              params.append('templateId', this.resource.templateid)
              params.append('vmUUID', this.resource.id)
            } else {
              params.append('counter', this.addSoftwareLicensesAmount)
              params.append('tagId', this.submitTagInput)
              params.append('templateId', this.resource.templateid)
              params.append('vmUUID', this.resource.id)
            }
            await wpapi.createTagEntryForVm(params).then(response => {
            }).catch(error => {
              this.$notifyError(error)
            })
            break
          }

        case 'delete':
          this.modalLoading = true
          if (['Admin'].includes(this.$store.getters.userInfo.roletype)) {
            // Case if Admin deletes VM Licenses
            if (this.resource.templatetype) {
              // if resource is Template
              params.append('tagId', this.modalInputTagId)
              params.append('templateUUID', this.resource.id)
              wpapi.deleteLicenseTagFromTemplate(params).then(response => {
              }).catch(error => {
                this.$notifyError(error)
              })
              break
            } else {
              // if resource is VM
              params.append('accountName ', this.resource.account)
              params.append('tagId', this.modalInputTagId)
              params.append('vmUUID', this.resource.id)
              await wpapi.deleteVmTagFromVmDbAsAdmin(params).then(response => {
              }).catch(error => {
                this.$notifyError(error)
              })
              break
            }
          } else {
            // Case if User deletes VM Licenses
            // if logged in user is a ResellerAdmin, use Account uuid of the VM Owner instead of accountUuid of the Account that is logged in currently
            if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.resource.account !== this.accountName) {
              params.append('accountUUID', this.subAccountUuid)
            } else { params.append('accountUUID', this.accountUuid) }
            params.append('tagId', this.modalInputTagId)
            params.append('vmUUID', this.resource.id)
            await wpapi.deleteVmTagFromVmDb(params).then(response => {
            }).catch(error => {
              this.$notifyError(error)
            })
          }
          break
        case 'edit':
          if (!this.validateInput('editLicenseInput', this.currentSoftwareLicenses)) {
            return
          }
          this.modalLoading = true
          if (['Admin'].includes(this.$store.getters.userInfo.roletype)) {
            // Case if Admin edits VM Licenses
            params.append('accountName', this.resource.account)
            params.append('counter', this.currentSoftwareLicenses)
            params.append('tagId', this.modalInputTagId)
            params.append('vmUUID', this.resource.id)
            wpapi.editVmLicenseTagInVmDbAsAdmin(params).then(response => {
            }).catch(error => {
              this.$notifyError(error)
            })
            break
          } else {
            // Case if User edits VM Licenses
            // if logged in user is a ResellerAdmin, use Account uuid of the VM Owner instead of accountUuid of the Account that is logged in currently
            if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.resource.account !== this.accountName) {
              params.append('accountUUID', this.subAccountUuid)
            } else { params.append('accountUUID', this.accountUuid) }
            params.append('counter', this.currentSoftwareLicenses)
            params.append('tagId', this.modalInputTagId)
            params.append('vmUUID', this.resource.id)
            wpapi.editVmLicenseTagInVmDb(params).then(response => {
              this.getAllTagsApi()
            }).catch(error => {
              this.$notifyError(error)
            })
            break
          }
      }
      this.reloadTagData()
      this.errors.addLicenseInput.status = ''
      this.errors.editLicenseInput.status = ''
    },
    /**
     * Close all possible Modals
     * @public
     */
    closeAction () {
      this.showAddLicenseModal = false
      this.showEditLicenseModal = false
      this.showDeleteLicenseModal = false
      this.modalInputTagColor = ''
      this.modalInputTagText = ''
      this.modalInputTagType = ''
      this.modalInputTagId = ''
      this.modalLoading = false
      this.showStartVMModal = false
      this.showStopVmModal = false
    },
    validateInput (input, value) {
      this.errors[input].status = ''
      this.errors[input].message = ''

      if (value === null || value === undefined || value.length === 0) {
        this.errors[input].status = 'error'
        this.errors[input].message = this.$t('message.error.required.input')
        return false
      } else return true
    },
    async reloadTagData () {
      await this.getTagsFromVm(this.resource.id)
      await this.fetchNrOfSoftwareLicencesAllVm(this.resource.id)
      if (this.resource.templatetype) {
        this.getTemplateLicenseTagInfos()
      }
      this.showAddLicenseModal = false
      this.showEditLicenseModal = false
      this.showDeleteLicenseModal = false
      this.modalInputTagColor = ''
      this.modalInputTagText = ''
      this.modalInputTagType = ''
      this.modalInputTagId = ''
      this.modalLoading = false
    },
    async fetchNrOfSoftwareLicencesAllVm (vmUuid) {
      this.licenseLoading = true
      var tags = this.vmLicenceTags
      var licenseList = []
      for (var tag in tags) {
        await wpapi.getSoftwareLicenceByVm(vmUuid, tags[tag].id).then(response => {
          var tempobject = { tagID: tags[tag].id, licenseCounter: response.data }
          licenseList.push(tempobject)
        })
      }
      this.nrOfSoftwareLicenses = licenseList
      this.licenseLoading = false
    },
    async getDiscountList () {
      await wpapi.discountListApi(this.accountUuid).then(response => {
        this.discountList = response.data
      })
        .catch(error => {
          this.$notifyError(error)
        })
    },
    async getTagsFromVm (id) {
      this.licenseLoading = true
      await wpapi.getAllTagsForVM(id).then(response => {
        this.vmLicenceTags = response.data
      })
        .catch(error => {
          this.$notifyError(error)
        })
      this.licenseLoading = false
    },
    // /WP Implementation
    setData () {
      if (this.resource.nic && this.resource.nic.length > 0) {
        this.ipaddress = this.resource.nic.filter(e => { return e.ipaddress }).map(e => { return e.ipaddress }).join(', ')
      } else {
        this.ipaddress = this.resource.ipaddress
      }
    },
    toSize (kb) {
      if (!kb) {
        return '0 KB'
      }
      if (kb < 1024) {
        return kb + ' KB'
      }
      if (kb < 1024 * 1024) {
        return parseFloat(kb / 1024.0).toFixed(2) + ' MB'
      }
      return parseFloat(kb / (1024.0 * 1024.0)).toFixed(2) + ' GB'
    },
    getUserKeys () {
      if (!('getUserKeys' in this.$store.getters.apis)) {
        return
      }
      api('getUserKeys', { id: this.resource.id }).then(json => {
        this.showKeys = true
        this.newResource.secretkey = json.getuserkeysresponse.userkeys.secretkey
        this.$emit('change-resource', this.newResource)
      })
    },
    getTags () {
      if (!('listTags' in this.$store.getters.apis) || !this.resource || !this.resource.id) {
        return
      }
      this.loadingTags = true
      this.tags = []
      const params = {
        listall: true,
        resourceid: this.resource.id,
        resourcetype: this.resourceType
      }
      if (this.$route.meta.name === 'project') {
        params.projectid = this.resource.id
      }
      api('listTags', params).then(json => {
        if (json.listtagsresponse && json.listtagsresponse.tag) {
          this.tags = json.listtagsresponse.tag
        }
      }).finally(() => {
        this.loadingTags = false
      })
    },
    isAdminOrOwner () {
      return ['Admin'].includes(this.$store.getters.userInfo.roletype) ||
        (this.resource.domainid === this.$store.getters.userInfo.domainid && this.resource.account === this.$store.getters.userInfo.account) ||
        (this.resource.project && this.resource.projectid === this.$store.getters.project.id)
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(function () {
        this.$refs.input.focus()
      })
    },
    handleKeyChange (e) {
      this.inputKey = e.target.value
    },
    handleValueChange (e) {
      this.inputValue = e.target.value
    },
    handleInputConfirm () {
      if (this.inputKey === 'marketplace' && this.inputValue === 'true' && !['Admin'].includes(this.$store.getters.userInfo.roletype)) {
        this.tagReservedForAdmin = true
      } else {
        this.tagReservedForAdmin = false
        const args = {}
        this.loadingTags = true
        args.resourceids = this.resource.id
        args.resourcetype = this.resourceType
        args['tags[0].key'] = this.inputKey
        args['tags[0].value'] = this.inputValue
        api('createTags', args).then(json => {
        }).finally(e => {
          this.getTags()
        })

        this.inputVisible = false
        this.inputKey = ''
        this.inputValue = ''
      }
    },
    handleDeleteTag (tag) {
      const args = {}
      this.loadingTags = true
      args.resourceids = this.resource.id
      args.resourcetype = this.resourceType
      args['tags[0].key'] = tag.key
      args['tags[0].value'] = tag.value
      api('deleteTags', args).then(json => {
      }).finally(e => {
        this.getTags()
      })
    },
    setResourceOsType (name) {
      this.newResource.ostypename = name
      this.$emit('change-resource', this.newResource)
    },
    getRouterQuery (item) {
      const query = {}
      if (item.value) {
        query[item.param] = this.resource[item.value]
      } else {
        if (item.param === 'account') {
          query[item.param] = this.resource.name
          query.domainid = this.resource.domainid
        } else if (item.param === 'keypair') {
          query[item.param] = this.resource.name
        } else {
          query[item.param] = this.resource.id
        }
      }

      return query
    },
    toggleVmStatus (check) {
      if (check === false) {
        this.showStopVmModal = true
      } else if (check === true) {
        this.showStartVMModal = true
      }
    },
    /**
     * Handles a submit of the "Stop VM" Modal by calling the according API (stopVirtualMachine).
     * @public
     */
    handleStopVmSubmit () {
      this.$emit('stop-vm-submit', this.switchIsChecked)
      /* this.loadingStop = true
      this.modalLoading = true
      api('stopVirtualMachine', {
        id: this.resource.id,
        forced: this.switchIsChecked
      }).then(response => {
        this.$store.dispatch('AddAsyncJob', {
          title: this.$t('label.action.stop.instance'),
          jobid: response.stopvirtualmachineresponse.jobid,
          status: 'progress'
        })
        this.$pollJob({
          jobId: response.stopvirtualmachineresponse.jobid,
          errorMessage: this.$t('message.stop.instance.failed'),
          loadingMessage: `${this.$t('label.action.stop.instance.processing')} ${this.apiInputVm.name}`,
          catchMessage: this.$t('error.fetching.async.job.result'),
          successMethod: () => {

          }
        })
        this.closeAction()
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        this.fetchData(false)
        this.loadingStop = false
        this.modalLoading = false
      }) */
    },
    fetchData () {
      this.$emit('fetch-data')
    },
    async fetchProxyData () {
      var getProxyparams = new FormData()
      getProxyparams.append('accountid', this.vpcOwnerAccUuid)
      getProxyparams.append('vpcid', this.$route.params.id)
      await api2.getProxySettings(getProxyparams).then(response => {
        response.data.proxyid ? this.proxyBoolean = true : this.proxyBoolean = false
        this.proxyId = response.data.proxyid
        this.proxyDomain = response.data.domain
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    async fetchProxyClients () {
      var proxyClientParams = new FormData()
      proxyClientParams.append('accountid', this.vpcOwnerAccUuid)
      proxyClientParams.append('vpcid', this.$route.params.id)
      proxyClientParams.append('proxyid', this.proxyId)
      await api2.getProxyClients(proxyClientParams).then(response => {
        this.proxyClientList = response.data
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    // enabling proxy
    async addProxyClientToVm (vm) {
      this.fetchProxyClientsLoading = true
      var params = new FormData()
      params.append('vpcid', this.$route.params.id)
      params.append('accountid', this.vpcOwnerAccUuid)
      params.append('virtualmachineid', vm.id)
      await api2.addProxyClient(params).then(response => {
        if (response.data.status === 'ok') {
          this.createConfirmationModal('VM ' + vm.name + ' wurde zum Proxy hinzugefügt')
          this.fetchProxyClients()
        }
        this.fetchProxyClientsLoading = false
      }).catch(error => {
        this.$notifyError(error)
        this.fetchProxyClientsLoading = false
      })
    },
    redirectProxyLink (vm) {
      if (this.proxyDomain !== undefined) {
        var a = document.createElement('a')
        a.href = 'https://' + this.proxyDomain + '/' + vm.name
        a.setAttribute('target', '_blank')
        a.click()
      } else {
        this.$notification.error({
          message: this.$t('label.error'),
          description: 'There was a Problem generating the Proxy Link'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cyberCloudLink:hover {
  color: #3D7CAF;
  cursor: pointer;
}
.tagToRight {
  margin-left: 8px;
}
.shieldIcon {
  width:1.5rem;
  height:1.7rem;
  margin-right: 10px;
}
.bold {
  font-weight: bold;
}

.sliderAndInput {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
:deep(.ant-card-body) {
  padding: 30px;
}

.resource-details {
  text-align: center;
  margin-bottom: 20px;

  &__name {
    display: flex;
    align-items: center;

    .avatar {
      margin-right: 20px;
      overflow: hidden;
      min-width: 50px;
      cursor: pointer;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .name {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 1;
      word-break: break-all;
      text-align: left;
    }

  }
}
.resource-detail-item {
  margin-bottom: 20px;
  word-break: break-all;

  &__details {
    display: flex;
    align-items: center;

    &--start {
      align-items: flex-start;

      i {
        margin-top: 4px;
      }

    }

  }

  .anticon {
    margin-right: 10px;
  }

  &__label {
    margin-bottom: 5px;
    font-weight: bold;
  }

}
.user-keys {
  word-wrap: break-word;
}
.account-center-tags {
  .ant-tag {
    margin-bottom: 8px;
  }

  a {
    display: block;
    margin-bottom: 10px;
  }

}
.title {
  margin-bottom: 5px;
  font-weight: bold;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: -10px;

  .ant-tag {
    margin-right: 10px;
    margin-bottom: 10px;
    height: auto;
  }

}

.progress-bar {
  padding-right: 60px;
  width: 100%;
}

.upload-icon {
  position: absolute;
  top: 70px;
  opacity: 0.75;
  left: 70px;
  font-size: 0.75em;
  padding: 0.25rem;
  background: rgba(247, 245, 245, 0.767);
  border-radius: 50%;
  border: 1px solid rgba(177, 177, 177, 0.788);
}
</style>
